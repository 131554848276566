import React from 'react'

export default ({ portfolio }) => {
    return (
        <section className="page-section bg-light" id="portfolio">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">My company projects</h2>
                    <h3 className="section-subheading text-muted">Coming soon....</h3>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-sm-6 mb-4">
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-toggle="modal" href="#portfolioModal1">
                                <div className="portfolio-hover">
                                    <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                                </div>
                                <img className="img-fluid" src="assets/img/portfolio/Android.png" alt=""/>
                            </a>
                            <div className="portfolio-caption">
                                <div className="portfolio-caption-heading">Android project</div>
                                <div className="portfolio-caption-subheading text-muted">coming soon....</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4">
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-toggle="modal" href="#portfolioModal2">
                                <div className="portfolio-hover">
                                    <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                                </div>
                                <img className="img-fluid" src="assets/img/portfolio/Apple.jpg" alt=""/>
                            </a>
                            <div className="portfolio-caption">
                                <div className="portfolio-caption-heading">IOS projects</div>
                                <div className="portfolio-caption-subheading text-muted">coming soon....</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4">
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-toggle="modal" href="#portfolioModal3">
                                <div className="portfolio-hover">
                                    <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                                </div>
                                <img className="img-fluid" src="assets/img/portfolio/iot.jpeg" alt=""/>
                            </a>
                            <div className="portfolio-caption">
                                <div className="portfolio-caption-heading">Internet of things</div>
                                <div className="portfolio-caption-subheading text-muted">coming soon....</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0">
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-toggle="modal" href="#portfolioModal4">
                                <div className="portfolio-hover">
                                    <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                                </div>
                                <img className="img-fluid" src="assets/img/portfolio/robot.jpeg" alt=""/>
                            </a>
                            <div className="portfolio-caption">
                                <div className="portfolio-caption-heading">Robotics projects</div>
                                <div className="portfolio-caption-subheading text-muted">coming soon....</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4 mb-sm-0">
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-toggle="modal" href="#portfolioModal5">
                                <div className="portfolio-hover">
                                    <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                                </div>
                                <img className="img-fluid" src="assets/img/portfolio/web.jpeg" alt=""/>
                            </a>
                            <div className="portfolio-caption">
                                <div className="portfolio-caption-heading">Website templates</div>
                                <div className="portfolio-caption-subheading text-muted">coming soon....</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-toggle="modal" href="#portfolioModal6">
                                <div className="portfolio-hover">
                                    <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                                </div>
                                <img className="img-fluid" src="assets/img/portfolio/car.jpeg" alt=""/>
                            </a>
                            <div className="portfolio-caption">
                                <div className="portfolio-caption-heading">Digital Car Dashboard</div>
                                <div className="portfolio-caption-subheading text-muted">coming soon....</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
