import React from 'react';
import './App.css';
import Portfolio from "./components/Portfolio";
import emailjs from 'emailjs-com';
import swal from 'sweetalert';

function App() {
  const portfolioLinks = [
    {
      title: 'Threads',
      caption: 'Illustration'
    },
    {
      title: 'Explore',
      caption: 'Graphic Design'
    },
    {
      title: 'Finish',
      caption: 'Identity'
    },
    {
      title: 'Lines',
      caption: 'Branding'
    },
    {
      title: 'Southwest',
      caption: 'Website Design'
    },
    {
      title: 'Window',
      caption: 'Photography'
    },
    {
      title: 'Pizza',
      caption: 'I love pizza!'
    }
  ]

  function sendEmail(e){
    e.preventDefault();

    emailjs.sendForm('gmail', 'bogoai_config', e.target, 'user_srAx82fRfRpfZ15EzCRec')
        .then((result) => {
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
        });
    e.target.reset()
    swal("Thank you for Contact!", "Our team has reply quickly!", "success");
  }



  return (
      <div className="App">
        <li class="navbar navbar-expand-lg navbar-dark bg-white" id="mainNav">
          <div className="container">
            <a className="navbar-brand js-scroll-trigger" href="#page-top"><img src="assets/img/cover.png" height="60px" width="200px" alt="" /></a>
            <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
              <i className="fa fa-bars ml-1"></i>
            </button>
            <div className="collapse navbar-collapse " id="navbarResponsive">
              <ul className="navbar-nav text-uppercase ml-auto "  >
                <li className="nav-item ">
                  <a className="nav-link js-scroll-trigger" href="#home">Home</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link js-scroll-trigger" href="#services">Services</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link js-scroll-trigger" href="#portfolio">Projects</a>
                </li>
                <li className="nav-item ">
                  <a className="nav-link js-scroll-trigger" href="#about">About</a>
                </li>
                <li className="nav-item ">
                  <a className="nav-link js-scroll-trigger" href="#team">Team</a>
                </li>
                <li className="nav-item ">
                  <a className="nav-link js-scroll-trigger" href="#contact">Contact</a>
                </li>
              <li>
                <a className="btn btn-light" href="Login.html">
                  <button className="btn " type="button">SIGN IN</button>
                </a>
              </li>
              </ul>
            </div>
          </div>

        </li>
        <header className="masthead" id="home">
          <div className="container">
            <div className="intro-text">
              <div className="intro-lead-in"></div>
              <div className="intro-heading text-uppercase"></div>
            </div>
          </div>
        </header>


        <section className="page-section" id="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="section-heading text-uppercase">Services</h2>
                <h3 className="section-subheading text-muted">Bogo Artificial inteligence pvt</h3>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-md-4">
          <span className="fa-stack fa-4x">
            <i className="fa fa-circle fa-stack-2x text-primary"></i>
            <i className="fa fa-android fa-stack-1x fa-inverse"></i>
          </span>
                <h4 className="my-3">Android developing</h4>
                <p className="card-text bold">Do you want to develop an Android app to enhance the day-to-day operational efficiency of your firm and its daily practice? Discuss your requirements with us and we will be suggesting the best Android web design based app. </p>
              </div>
              <div className="col-md-4">
          <span className="fa-stack fa-4x">
            <i className="fa fa-circle fa-stack-2x text-primary"></i>
            <i className="fa fa-laptop fa-stack-1x fa-inverse"></i>
          </span>
                <h4 className="my-3">Responsive Design</h4>
                <p className="card-text bold">Developers at bogo desiginers are skilled and experienced and using the latest website building technologies to give out the performing site </p>
              </div>
              <div className="col-md-4">
          <span className="fa-stack fa-4x">
            <i className="fa fa-circle fa-stack-2x text-primary"></i>
            <i className="fa fa-lock fa-stack-1x fa-inverse"></i>
          </span>
                <h4 className="my-3">Security</h4>
                <p className="card-text bold">Software Security Assurance (SSA) is the process of ensuring that software is designed to operate at a level of security that is consistent with the potential harm that could result from the loss, inaccuracy, alteration, unavailability, or misuse of the data and resources that it uses, controls, and protects.</p>
              </div>
            </div>
          </div>
        </section>


        <Portfolio portfolioLinks={portfolioLinks}></Portfolio>


        <section className="page-section" id="about">
          <div className="container">
            <div className="text-center">
              <h2 className="section-heading text-uppercase">About</h2>
              <h3 className="section-subheading text-muted">Bogo Ai</h3>
            </div>
            <ul className="timeline">
              <li>
                <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/1.jpg" alt=""/></div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h4>2020-2021</h4>
                    <h4 className="subheading">Our Beginnings</h4>
                  </div>
                  <div className="timeline-body"><p className="text-muted">Bogo ai started 2020 at may24th!</p></div>
                </div>
              </li>
              <li className="timeline-inverted">
                <div className="timeline-image">
                  <img className="rounded-circle img-fluid" src="assets/img/about/3.jpg" alt=""/>
                </div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h4>May 24th</h4>
                    <h4 className="subheading">An Agency is Born</h4>
                  </div>
                  <div className="timeline-body"><p className="text-muted">our company was started with four people</p></div>
                </div>
              </li>
              <li>
                <div className="timeline-image">
                  <img className="rounded-circle img-fluid" src="assets/img/about/2.jpg" alt=""/>
                </div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h4 className="subheading">Transition to Full Service</h4>
                  </div>
                  <div className="timeline-body"><p className="text-muted">we are developing android and all mobile application software,to develop on secure and re-usability software.As a reputed IT firm, we understand that our clients have various demands in software development. Moreover, to meet all those demands you must possess expertise in all the various languages and platform. Below are some of the platform in which we provide our services</p></div>
                </div>
              </li>
              <li className="timeline-inverted">
                <div className="timeline-image">
                  <img className="rounded-circle img-fluid" src="assets/img/about/4.png" alt=""/>
                </div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h4 className="subheading">Quality and security assurence</h4>
                  </div>
                  <div className="timeline-body"><p className="text-muted">We focus on quality. Only native applications at that so that we can be really damn good at it. This includes all the major platforms & also any other quirky systems you might might across. We draw on our extensive experience of deploying robust servers & integrating with existing setups to deliver the best.</p></div>
                </div>
              </li>
              <li className="timeline-inverted">
                <div className="timeline-image">
                  <img className="rounded-circle img-fluid" src="assets/img/about/5.jpg" alt=""/>
                </div>
              </li>
            </ul>
          </div>
        </section>


        <section className="bg-light page-section rl" id="team">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="section-heading text-uppercase">Our Team</h2>
                <h3 className="section-subheading text-muted">Bogo Ai</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-sm-6 mb-4" >
                <div className="team-member">
                  <img className="mx-auto rounded-circle" src="assets/img/team/1.jpg" alt=""/>
                  <h4>Kalidass</h4>
                  <p className="text-muted">Founder and CEO</p>
                  <ul className="list-inline social-buttons">
                    <li className="list-inline-item">
                      <a href="#something">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#something">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mb-4">
                <div className="team-member">
                  <img className="mx-auto rounded-circle" src="assets/img/team/4.jpg" alt=""/>
                  <h4>Harlan-ME.,MIT</h4>
                  <p className="text-muted">CTO</p>
                  <ul className="list-inline social-buttons">
                    <li className="list-inline-item">
                      <a href="#somthing" target="null">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#something" target="null">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mb-4">
                <div className="team-member">
                  <img className="mx-auto rounded-circle" src="assets/img/team/4.png" alt=""/>
                  <h4>Mathivanan</h4>
                  <p className="text-muted">Backend Developer</p>
                  <ul className="list-inline social-buttons">
                    <li className="list-inline-item">
                      <a href="https://twitter.com/AjithKu24714579" target="null">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#something">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mb-4">
                <div className="team-member">
                  <img className="mx-auto rounded-circle" src="assets/img/team/2.jpeg" alt=""/>
                  <h4>Subash</h4>
                  <p className="text-muted">Data Scientist</p>
                  <ul className="list-inline social-buttons">
                    <li className="list-inline-item">
                      <a href="https://twitter.com/IyyappanSubash" target="null">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="https://www.linkedin.com/in/subash-iyyappan-197274187" target="null">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-8 mx-auto text-center">
                <p className="large text-muted"> For new projects, this is our initial engagement. We will learn about your idea from our angle as software consultants, understand what you envision your system to be and the scope of the required software.</p>
              </div>
            </div>
          </div>
        </section>


        <div className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-6 my-3">
                <a href="#!"><img className="img-fluid d-block mx-auto" src="assets/img/logos/sponser1.jpeg" alt=""/></a>
              </div>
              <div className="col-md-3 col-sm-6 my-3">
                <a href="#!"><img className="img-fluid d-block mx-auto" src="assets/img/logos/sponser2.jpeg" alt=""/></a>
              </div>
              <div className="col-md-3 col-sm-6 my-3">
                <a href="#!"><img className="img-fluid d-block mx-auto" src="assets/img/logos/sponsor3.jpeg"
                                  alt=""/></a>
              </div>
              <div className="col-md-3 col-sm-6 my-3">
                <a href="#!"><img className="img-fluid d-block mx-auto" src="assets/img/logos/sponser4.jpeg"
                                  alt=""/></a>
              </div>
            </div>
          </div>
        </div>

        <section className="page-section" id="contact">

          <div className="container">
            <form onSubmit={sendEmail}>
              <div className="text-center">
                <h2 className="section-heading text-uppercase">Contact Us</h2>
                <h3 className="section-subheading text-muted">Bogo Ai</h3>
              </div>
              <form id="contactForm" name="sentMessage" data-Validate="validate">
                <div className="row align-items-stretch mb-5">
                  <div className="col-md-6">
                    <form id="form" action="/" method="GET"/>
                    <div className="form-group">
                      <input className="form-control" id="name" type="text" placeholder="Your Name *" required="required"
                             data-validation-required-message="Please enter your name." name="name"/>
                      <p className="help-block text-danger"></p>
                    </div>
                    <div className="form-group">
                      <input className="form-control" id="email" type="email" placeholder="Your Email *" required="required"
                             data-validation-required-message="Please enter your email address." name="email"/>
                      <p className="help-block text-danger"></p>
                    </div>
                    <div className="form-group mb-md-0">
                      <input className="form-control" id="phone" type="number" placeholder="Your Phone *" required="required"
                             data-validation-required-message="Please enter your phone number." name="phone" />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-group-textarea mb-md-0">
                      <textarea className="form-control" id="message" placeholder="Your Message *" required="required" data-validation-required-message="Please enter a message." name="message"></textarea>
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <div>  id="success"</div>
                  <button  className="btn btn-primary btn-xl text-uppercase" id="sendMessageButton" type="submit">Send
                    Message
                  </button>
                </div>
              </form>
            </form>
          </div>
        </section>


        <footer className="footer">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-4">
                <span className="copyright">Copyright &copy;Bogo Ai </span>
              </div>
              <div className="col-md-4">
                <ul className="list-inline social-buttons">
                  <li className="list-inline-item">
                    <a href="https://twitter.com/bogo_ai" target="null">
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.facebook.com/bogo.ai.77" target="null">
                      <i className="fa fa-facebook-f"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.linkedin.com/company/bogo-ai" target="null">
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul className="list-inline quicklinks">
                  <li className="list-inline-item">
                    <a href="privacy.html"><b>Privacy Policy</b></a>
                  </li>
                  <li className="list-inline-item">
                    <a href="term.html"><b>Terms of Use</b></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>

  );
}

export default App;
